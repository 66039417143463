// content
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { GetUserForm, NumberFormat } from "../../services/srvc-utilities";

import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";

import { AssetsDetails, UnitsCreate } from "../../services/srvc-assets-realm";
import { AssetsTransferCreate } from "../../services/srvc-transfers-assets-realm";
import { TokensDetails } from "../../services/srvc-tokens-realm";
import { AssetsCreatorsOffersList, AssetsOffersCreate } from "../../services/srvc-offers-realm";

export default function AssetsOwnedDetailsAdminModule() {


  const {id} = useParams()

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  
  const [submit, setSubmit] = useState(false);
  
  const [data, setData] = useState();
  const [balance, setBalance] = useState({number:'', ticker:'STX'})

  const [showModal, setShowModal] = useState(false);

  const [offers, setOffers] = useState([])

  const [refresh, setRefesh] = useState(false)

  const [sale, setSale] = useState('')
  const [rate, setRate] = useState('')
  const [user, setUser] = useState('')

  const [memo, setMemo] = useState('')

  const [unit, setUnit] = useState({status: {sale:true}})

  useEffect(() => {
    setLoader(true)
    if (asset) {
      const fetchData = async () => {
       
        var res = await  TokensDetails({ data: { item: id }, srvc: "" });
        if (res.stat) {
          setData(res.data);
          var bal = res.data.balance.mint - res.data.balance.sale - res.data.balance.burn
          setBalance({number:bal, ticker:'STX'})

          var resu = await AssetsCreatorsOffersList({data:{user:asset.item, unit: res.data.unit.item }})
          // console.log(resu)
          if(resu.stat) setOffers(resu.data.list)
        }
        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, [refresh]);

  
  const handleSubmit = async () => {
    setMemo('Please Wait ...')
    setSubmit(true);
    setShowModal(true); // Show the modal
    var datax = {
      user: { mail:user },
      unit: {item:data.unit.item},
      sale: {number: sale, ticker: "STX"},
      rate: { number: rate, ticker: "INR" },
      debit: data.user || {"name": '', "mail":'', "item":''},
    };
    console.log(datax)
    var res = await AssetsOffersCreate({ data: datax, srvc: "" });
    //console.log(res)
    if(res.stat){
        setMemo("Offer Created successfully.")
        setTimeout(()=>{
            window.location.reload(true)
        },1000)
    }
    else {
        setMemo(res.memo)
    }

  };

  const ResetValues = async()=>{
    setRate('')
    setSale('')
    setUser('')
    setMemo('')

  }

 

  if (loader) {
    return (
      <>
        <WebbLoaderSmall />
      </>
    );
  }

  return (
    <div className="">
      <div className="">
        <div className="rounded-xd shadow-sm border">
        <div className="card border-none back-color-none h-100" >
            
            <img src={data?.media?.link } className="card-img-top ratio ratio-16x9 rounded-xd rounded-bottom-none border-none" alt="..." ></img>
            
            <div className="card-body border-none back-color-none">
            <p className="card-text text-small m-0">{data?.unit?.number || '******'}</p>
            <h5 className="card-title text-normal text-bold m-0">{data?.unit?.name || data?.name || data?.meta?.name || ''}</h5>
            <p className="card-text text-small m-0">{data?.unit?.memo || data?.memo || data?.meta?.memo || ''}</p>
            </div>
        
            <div className={data?.balance?.show ? "card-footer back-color-wite border-none back-color-none" : 'd-none'}>
            <p className= { "card-text text-bold m-0"} >{'Balance'}{'  : '}{data?.balance?.number || '******'}{' '}{'Units'}</p>
            <a href="#" className="btn btn-primary d-none">Go somewhere</a>
            <div className="mb-1"></div>
            </div>

            </div>
        </div>

        <div className="bg-white border rounded-xd p-3 py-4 d-flex mt-2">
            <div className="me-auto fw-bold">Available Balance </div>
            <div className="text-end fw-bold">{NumberFormat(balance.number, "w")} {balance.ticker}</div>
        </div>
      </div>


      {/* if unit.status.sale == false */}


      <div className={unit.status.sale ? "d-flex mt-3" :'d-none' }>
        <div className="me-auto d-none">
            <button className="btn btn-primary text-small rounded-xx" disabled>Burn</button>
        </div>
        <div className="text-end">
            <button className="btn btn-primary  rounded-xx" data-bs-toggle="modal" data-bs-target="#exampleModal1" disabled={balance.number==0}>Transfer</button>
        </div>

      </div>

      <div className="mt-5  ">
        <h6 className="text-secondary ">Offers</h6>
        {
            offers.length>0 && offers.map((item)=>{
                return(
                    <div className="p-3 bg-white mt-1 mb-2 rounded-xd d-flex">
                        <div className="me-auto">
                            
                            <p className="m-0 fw-bold">
                                <i class={`bx bxs-circle text-${item.stat==0  ?'secondary' : item.stat==6 ? 'success' : item.stat == 4 || item.stat== 8 ? 'danger' : 'warning'  }`}></i>
                               <span className="mx-1"> {item?.user?.name || '*****'}</span>
                            </p>
                            
                            <p className="m-0 mx-3">{item?.user?.mail || '*****'}</p>
                        </div>
                        <div>
                            <p className="m-0 mx-2 text-small">{item?.sale?.nmbr || '*****'} STX</p>
                            <p className="m-0 mx-2 text-small">{item?.rate[0]?.rate?.nmbr || '*****'} INR</p>
                        </div>
                    </div>
                )

            }) 
        
        }

      </div>


{/* modal */}
      <div
        className="modal fade rounded-xd"
        id="exampleModal1"
        tabindex="-1"
        data-bs-backdrop="static" // Prevent closing when clicking outside the modal
        data-bs-keyboard="false" // Prevent closing with the Escape key
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog rounded-xd mt-5">
          <div className="modal-content w-100" >
            <div className="modal-header border-none align-middle">
              <p className="text-lead m-0" id="exampleModalLabel">
                Create Offer
              </p>
              <button
                onClick={()=> ResetValues()}
                type="button"
                className="btn-close "
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <label className="mb-1">Shares To Sell</label>
              <input min={0} max={9} type="number" value={sale} onChange={(e)=> setSale(e.target.value)} className="form-control mb-2" />
              <label className="mb-1">Rate</label>
              <input type="number"  value={rate} onChange={(e)=> setRate(e.target.value)} className="form-control mb-2" />
              <label className="mb-1">User (Email)</label>
              <input  value={user} onChange={(e)=> setUser(e.target.value)} className="form-control mb-2" />
            </div>

            <p className="text-small mx-3 text-primary mt -3">{memo}</p>

            <div class="modal-footer d-flex">
               <div className="me-auto"> <button onClick={()=>ResetValues()} type="button" className="btn btn-secondary rounded-xx text-small" data-bs-dismiss="modal">Close</button></div>
               <div className="text-end"> <button type="button" onClick={()=>handleSubmit()} className="btn btn-primary rounded-xx text-small" disabled={ !sale || !rate || !user || parseFloat(sale)> parseFloat(balance.number)}>Create</button></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
