// accounts
import * as Realm from 'realm-web';
import axios from "axios";
import services from '../data.services/data-services-main.json'

const base = (services.data.find(x => x.code == 'accounts')).link





// Function Calls
export const AuthTransactionUser = async (id) => {
  //auth transfer service
  // const cred = Realm.Credentials.apiKey(process.env.REACT_APP_MNDB_AUTH_TRXN);
  // const user = await acnt.logIn(cred);
  // return user
}


// read functions
export const BusinessAccountBalance = async (item) => {
  
  const basx = base + '/account/balance';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    //console.log (error);
    return {data: false}
  }
}


export const BaseAccountBalance = async (item) => {
  
  const basx = base + '/account.balance';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    //console.log (error);
    return {data: false}
  }
}



export const BaseAccountDetails = async (item) => {
  
  const basx = base + '/account/detail';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }
}


export const BusinessAccountDetails = async (item) => {
  
  const basx = base + '/account/detail';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }
}


export const CreateBaseAccount = async (item) => {

  const basx = base + '/account/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }
}



export const CreateBusinessAccount = async (item) => {

  const basx = base + '/account/create/va';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    var {data}=(error.response.data);
    // console.log(data.message)
    return {data: false, memo:data.message}
  }
}


export const BankAccountLink = async (item) => {

  const basx = base + '/account/link/bank';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    var {data}=(error.response);
    // console.log(error.response)
    return {data: data.data, status:data.status, memo:data.message}
  }
}

export const AccountsList = async (item) => {

  const basx = base + '/accounts/list';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    var {data}=(error.response);
    // console.log(error.response)
    return {data: data.data, status:data.status, memo:data.message}
  }
}




export const GetBaseAccount = async (item) => {

  const user = await AuthTransactionUser()
  const result = await user.functions.acntGetBaseAccount(JSON.stringify({
    ...item,
    webb: process.env.REACT_APP_WEBB_SITE_CLNT
  }))
  return JSON.parse(result);
}

export const GetAccountBalance = async (item) => {
  
  const user = await AuthTransactionUser()
  const result = await user.functions.acntGetAccountBalance(JSON.stringify({
    ...item,
    webb: process.env.REACT_APP_WEBB_SITE_CLNT
  }))
  return JSON.parse(result);
}

export const GetUserAccountsList = async (item) => {
  
  const user = await AuthTransactionUser()
  const result = await user.functions.acntGetUserAccountsList(JSON.stringify({
    ...item,
    webb: process.env.REACT_APP_WEBB_SITE_CLNT
  }));
  return JSON.parse(result);
}



// contacts
export const GetContactAccounts = async (item) => {
  const user = await AuthTransactionUser()
  const result = await user.functions.acntGetUserAccountsList(item)
  return (result);
}

export const SetContactAccounts = async (item) => {
  const user = await AuthTransactionUser()
  const result = await user.functions.acntCreateBaseAccount(item)
  return (result);
}

export const RemoveContactAccounts = async (item) => {
  const user = await AuthTransactionUser()
  const result = await user.functions.acntRemoveContactAccounts(item)
  return (result);
}


// account linking



export const CheckUPIAccount = async (item) => {
  const user = await AuthTransactionUser()
  const result = await user.functions.acntCheckUPIAccount(item)
  return (result);
}

export const CheckBankAccount = async (item) => {
  const user = await AuthTransactionUser()
  const result = await user.functions.acntCheckBankAccount(item)
  return (result);
}

export const LinkBankAccounts = async (item) => {
  const user = await AuthTransactionUser()
  const result = await user.functions.acntLinkBankAccount(item)
  return (result);
}

export const AccountsBaseBalance = async (item) => {
  const basx = base + "/accounts/base/balance";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AccountsTransitDetails = async (item) => {
  const basx = base + "/accounts/transit/details";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};