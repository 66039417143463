// content
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NumberFormat } from "../../services/srvc-utilities";

import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { GetUserForm } from "../../services/srvc-utilities";
import AssetsCardMedium from "../webx/assets-card-md";

import { TokensListUser } from "../../services/srvc-tokens-realm";
import media from '../../data.media/contrato-card.png'


export default function AssetsListUserFundedModule({ assetSearchData }) {

  const navigate = useNavigate();
  const asset = GetLocalUser();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        const datx = assetSearchData.searchTerm
          ? { user: asset.item, search: assetSearchData.searchTerm }
          : { user: asset.item };
        const result = await TokensListUser({
          data: datx,
        });
        console.log(result.data)
        if (result.stat) setData(result.data.list);

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, [assetSearchData.searchButton]);

  const handleClick = async (item) => {
    navigate(`/${asset.role}/asset/owned/${item}`);
  };

  if (loader)
    return (
      <>
        <div
          className="p-3 back-color-wite rounded-xd border align-middle"
          style={{}}
        >
          <span className="align-middle text-lead">
            <i className="bx bxs-info-circle text-color-wait"></i>
          </span>
          <span className="ms-1 text-color-tone">Please Wait...</span>
        </div>
      </>
    );

  if (!loader && (!data || data.length === 0))
    return (
      <>
        <div
          className="p-3 back-color-wite rounded-xd border align-middle"
          style={{}}
        >
          <span className="align-middle text-lead">
            <i className="bx bxs-info-circle text-color-success"></i>
          </span>
          <span className="ms-1 text-color-tone">No Assets</span>
        </div>
      </>
    );

  return (
    <>
      {/* data */}
      <div className="row row-cols-1 row-cols-md-2 g-3">
      {data && data.map((item, i) => (
        <div className="col" key={i}>
            
          <div className="media-standard" onClick={() => handleClick(item?.item)}>
            <img className="rounded-xd cursor" src={media} alt='...'></img>
              <p className="text-normal text-bold text-truncate" style={{position: 'absolute', left:'1rem', top: '1rem'}}>{item?.unit?.name}</p>
              <p className="" style={{position: 'absolute', left:'1rem', top: '2.5rem'}}>{item?.unit?.number}</p>
              <p className="text-lead m-0" style={{position: 'absolute', left:'1rem', bottom: '1rem'}}>
                {'Balance: '}
                {parseInt((item?.acbx?.mint || 0) - (item?.acbx?.sale || 0))}
              </p>
            </div>
          {/* <AssetsCardMedium data={item}/> */}
              
        </div>
      ))}
    </div>
    </>
  );
}


// <div
// className="cursor"
// key={i}
// onClick={() => handleClick(item?.item)}  // Use updatedItem
// >
// {/* <AssetsCardMedium data={updatedItem} />   */}


// </div>