// content
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AssetsCardMedium from "../webx/assets-card-md";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import { UserAccountList } from "../../services/srvc-users-realm";

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbLoaderSmall from "../webx/webb-loader-sm";

import media from '../../data.media/contrato-card.png'
// const media = 'https://img.freepik.com/free-vector/abstract-glowing-particles-background-blue-red-color_1017-26575.jpg?w=900'
// const media = 'https://img.freepik.com/free-photo/blockchain-technology-background-gradient-blue_53876-124646.jpg?w=300'


export default function TeamAccountListModule () {

  
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  // console.log(asset)
  
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([])


  useEffect( () => {
    setLoader(true)
    if (asset){
      const fetchData = async() => {
        setLoader(true);
        var res = await UserAccountList({data:{user:asset.item}, srvc:''})
        console.log(res)
        if(res.stat){
          var teams = res.data.list.filter(item => item.role=="team")
          // teams = Array.from(teams, x =>{ return ({
          //   meta: {name:x.name, memo: x?.memo || '******'}, 
          //   unit: {number: x.number},
          //   balance: {number: '0', ticker: '', show: false},
          //   item:x.item
          // })})
          setData(teams)
        }
        
        setLoader(false);
      }
      fetchData()
    } else {}
  },[]);

  if(loader) return (<div className=""> Please Wait... </div> )

  if(!loader && data.length==0) return( <>No Business Accounts</>)


  return (
  <>
    {/* info */}

    {/* data */}
    <div className="row row-cols-1 row-cols-md-2 g-3">
      {data && data.map((item, i) => (
        <div className="col" key={i}>
            
          <div className="media-standard">
            <img className="rounded-wd" src={media} alt='...'></img>
              <p className="text-bold" style={{position: 'absolute', left:'1rem', top: '1rem'}}>{item.name}</p>
              <p className="" style={{position: 'absolute', left:'1rem', top: '2.5rem'}}>{item.mail}</p>
              <p className="" style={{position: 'absolute', left:'1rem', bottom: '.5rem'}}>{item.number}</p>
            </div>
          {/* <AssetsCardMedium data={item}/> */}
              
        </div>
      ))}
    </div>

  </>

  )
}



{/* <div className="p-3"> */}
{/*  */}
{/* 
<p className="m-0" style={{}}>{item?.mail || '******'}</p>

<WebbDividerMedium />
<WebbDividerMedium />
<p className="m-0" style={{}}>{item?.number || '******'}</p>


<WebbDividerMedium />
<WebbDividerMedium />
<WebbDividerMedium />
<WebbDividerMedium />
</div> */}