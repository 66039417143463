import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";

import NavsButtonBack from "../webx/navs-button-back";
import NavsButtonNext from "../webx/navs-button-next";

import { GetUserForm, UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import { UserCertificateList, UserDocumentList } from "../../services/srvc-media-docs-realm";
import WebbSpinText from "../webx/webb-spintext";



export default function CertificatesDocumentListModule(props) {

  const asset = GetUserForm() === "user" ? GetLocalUser() : GetLocalBusiness();

  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();

  const [data, setData ] = useState([]);
  const search = props.data;
  // console.log (search)

  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(5)

  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()

  const [count, setCount] = useState()
  const [total, setTotal] = useState()

  const [text, setText] = useState('')


  useEffect( () => {

    if (asset){
      const fetchData = async() => {
        setLoader(true);
        const datx = {
          data: { user: asset.user, index:index, items: items, filters: search },
          user: asset.user
        }

        var result = await UserCertificateList({data: { user: asset.item, format: "cert" , items: items, index: index}, srvc:''})
        // console.log (result)
        setData(result.data.list)
        
        setCount(result.data.count)
        setTotal(Math.ceil(result.data.count/ items))
        setText(`${(index-1)*items+1} - ${index*items < result.data.count ? index*items : result.data.count} of ${result.data.count}`)

        setLoader(false);
      }
      fetchData()
    } else {}
  },[index, items, search]);


  const NextIndex = async() =>{
    if(total == index) {}
    else {
      setNextIndex(curr+1)
      setIndex(curr+1)
      setCurrentIndex(curr+1)
    }
  }

  const LastIndex = async()=>{
    if(index == 1) {}
    else{
      setLastIndex(curr-1)
      setIndex(curr-1)
      setCurrentIndex(curr-1)
    }
  }


  const handleDownload = async(item) => {

    if(item?.link && item?.link != '')
      {
        window.open(item.link)
      }
    if(item?.base64)
      {
        
        var base64String = item.base64.split(";base64,").pop(1)
        // console.log(base64String)
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl);
      }
    
   
    

  }

  if (loader){ return ( <> <WebbSpinText /> </> ) }
  if (!loader && (!data || data.length === 0)) { return ( <>  
  <div className="">
    <p className="text-muted">No Records</p>
  </div> </> ) }

  return (
  <>
    {/* data */}
    <div className="back-color-wite border rounded-xd">
    {data && data.length > 0 && data.map((item, i) => (
      <div className={i < data.length-1 ? 'border-bottom' : ''} key={i}>
        <div className="p-1" >
          <div className="p-2 d-flex cursor hilite rounded" onClick={()=>handleDownload(item)}>

            <div className="">
              <p className="m-0 mb-1">
                <span className={`d-none ${item.active ? 'text-color-success' : 'text-color-warning'}`}><i className="bx bxs-circle text-small"></i></span>
                <span className="text-normal text-bold m-0 text-success">{item.meta.name}</span>
              </p>
              <p className="m-0 mb-2 text-sm">{item.meta.memo || 'Share Certificates'}</p>
              <p className="m-0">ID: {item?.number || ''}</p>
              <p className="text-color-tone m-0">{(new Date(item.created)).toLocaleDateString()} {(new Date(item.created)).toLocaleTimeString()} </p>
              
            </div>

            <div className="ms-auto">
              {/* <i className="bi bi-chevron-right text-lead text-color-tint"></i>             */}
              <i class='bi bi-arrow-down-circle text-icon-sm' style={{fontSize:''}}></i>
            </div>

          </div>

        </div>
      </div>

    ))}
    </div>


    {/* navs */}
    <WebbDividerSmall />
    <div className="">
      <div className="d-flex justify-content-between">

        <div className="" onClick={()=>LastIndex()}>
          <NavsButtonBack />
        </div>

        <div className="">
          <p className="my-2 text-color-tone">{text}</p>
        </div>

        <div className="" onClick={()=>NextIndex()}>
          <NavsButtonNext />
        </div>

      </div>
    </div>
    
    
  </>
  )
}